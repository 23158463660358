/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { SanityProject, SanityWork, SanityEphemeron } from "types";
import { SEO, RichTextWithImage } from "components";
// import styles from "./richTextWithImagePageTemplate.module.scss";

const RichTextWithImagePageTemplate: React.FC<{
  pageContext: { data: SanityProject | SanityWork | SanityEphemeron };
}> = ({ pageContext: { data: archiveItem } }) => {
  return (
    <>
      <SEO
        title={archiveItem.information!.title}
        description={archiveItem.information.shortDescription}
      />
      <main>
        {archiveItem.information.content && (
          <RichTextWithImage content={archiveItem.information.content} />
        )}
      </main>
    </>
  );
};

export default RichTextWithImagePageTemplate;
